<script>
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ContentCourseDetails from '@/components/contents/ContentCourseDetails'

export default {
  name: 'CourseView',
  components: {
    Breadcrumbs,
    ContentCourseDetails
  },
  data () {
    return {
      id: this.$route.params.courseId,
      paths: [],
      courseRelatedPositions: [],
      title: '...'
    }
  },
  methods: {
    handleTitle (course) {
      this.title = course.title
      this.paths[1].text = `${this.$t('global:title.course')} ${course.title}`
    }
  },
  created () {
    this.paths.push({ text: 'main.header:discovery', href: `${this.getWorkspaceRoot()}/discovery` }, { text: `${this.$t('global:title.course')}...`, href: this.$route.path })
  }
}
</script>

<template>
  <section class="course-view--container center-small">
    <div class="default-header">
      <breadcrumbs :items="paths"/>
      <h2>{{ ($t('global:title.course') + ' ' + title) }}</h2>
    </div>

    <v-card class="course-view--content">
      <content-course-details :id="id" :onReady="handleTitle" />
    </v-card>
  </section>
</template>
<style lang="scss">
.course-view--container {
  .default-header{
    margin-bottom: 44px;
  }
  .course-view--content {
    margin-bottom: 80px;
  }
  .content-course-details-container {
    margin-top: 0;
    padding-top: 0;
    .content-course-details--header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 16px;
      background-color: #fafafa;
      h2 {
        line-height: 1em;
      }
    }
    .content-course-details--body {
      padding: 100px 40px 40px 40px;
    }
  }
}
</style>
